import React from "react"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../components/logo"
import { pageContact, contact__email, contact__logo } from "./contact.module.scss"
import { content } from "./pages.module.scss"

export default function ContactPage() {
  const currentLang = "en"
  return (
    <PageTransition>
      <Layout lang={currentLang} bodyColor={'white'}>
        <Seo
          lang={currentLang}
          title={"Contact"}
          keywords={[`powszuk`, `design`]}
        />
        <div className={`${content} ${pageContact}`}>
          <a href="mailto:sebastian@powszuk.com" className={contact__email}>sebastian@powszuk.com</a>
          <div className={contact__logo}>
            <Logo color={"rgba(24, 154, 141, 0.2)"} />
          </div>
        </div>
      </Layout>
    </PageTransition >
  )
}
